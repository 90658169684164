<template>
  <!-- 货区列表 -->
  <div class="container">
    <!-- 按钮列表 -->
    <div class="btnList1" ref="btnList">
      <el-button
        v-if="hasPermiss('zjck_cangKuHuoQu:add')"
        class="itemBtn btnColor"
        @click="addItem"
        >新建</el-button
      >
      <el-button
        v-if="hasPermiss('zjck_cangKuHuoQu:update')"
        class="itemBtn btnColor"
        @click="updateItem"
        >修改</el-button
      >
      <el-button
        v-if="hasPermiss('zjck_cangKuHuoQu:del')"
        class="itemBtn btnColor"
        @click="deletebut"
        >删除</el-button
      >
      <el-button class="itemBtn btnColor" @click="myModel">列表设置</el-button>
      <el-dropdown @command="handleCommand_dc">
        <el-button class="dropdownBtn">
          导出<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="dc">导出</el-dropdown-item>
          <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
          <el-dropdown-item command="scmb">保存(上传)模板</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 查询条件框 -->
    <el-form :model="queryData">
      <div ref="queryCriteria" class="HeadInputbox">
        <div class="item_right">
          <div class="singleinp">
            <div class="singleinpleft">仓库名称:</div>
            <div class="singleinpright">
              <el-select
                class="inputframe"
                v-model="queryData.storageName"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in storageAddr"
                  :key="item.id"
                  :label="item.storageName"
                  :value="item.storageName"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">货区名称:</div>
            <div class="singleinpright">
              <el-input
                class="queryInput"
                clearable
                v-model="queryData.cargoareaName"
                placeholder="请输入货区名称"
              ></el-input>
            </div>
          </div>
        </div>
        <div class="item_left">
          <div class="singleinpbtn" v-if="hasPermiss('zjck_cangKuHuoQu:query')">
            <el-button
              class="seniorbut"
              @click="queryBtn_ok"
              icon="el-icon-search"
              >查询</el-button
            >
            <el-tooltip content="查询项恢复初始状态" placement="top">
              <div class="condition" @click="queryBtn_cz">清除</div>
            </el-tooltip>
            <!-- <el-tooltip content="查询更多条件" placement="top">
              <div class="condition" @click="more">
                {{ MoreConditions }}
              </div>
            </el-tooltip> -->
          </div>
        </div>
      </div>
    </el-form>

    <!-- 表格 -->
    <div
      class="tableBox"
      ref="tableWrapper"
      :class="{ tableBox: TableStyle, tableBox1: fullscreen }"
    >
      <el-table
        :height="tableHeigth"
        ref="mytable"
        show-summary
        :summary-method="getSummaries"
        :data="tableData"
        :row-class-name="tableRowClassName"
        highlight-current-row
        :header-cell-style="{ background: '#f0f0f0' }"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column fixed="left" label="序号" type="index" width="60">
        </el-table-column>
        <el-table-column fixed="left" type="selection" width="60">
        </el-table-column>
        <template v-for="(item, index) in myTableHeard">
          <el-table-column
            :key="index"
            :label="item.name"
            :prop="item.field"
            sortable
            :min-width="item.width"
            :data-isTotal="item.isTotal"
            v-if="item && item.isShow"
          >
          </el-table-column>
        </template>
      </el-table>
    </div>

    <!-- 分页组件 -->
    <paging
      ref="pags"
      :pageNum="pageNum"
      :total="total"
      :sizeList="sizeList"
      :size="size"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></paging>

    <!-- //排序 -->
    <div>
      <sorttable
        :show_sortTableHeard="show_sortTableHeard"
        :allCheck="allCheck"
        :sortName="sortName"
        :tabelHeadeTitle="tabelHeadeTitle"
        :originalTabelHeadeTitle="originalTabelHeadeTitle"
        :selectLength="selectLength"
        @cancelSortHeard="cancelSortHeard"
        @confirmOk="confirmOk"
        @checktab="checktab"
        @checkAll="checkAll"
        @closeDias="closeDias"
      ></sorttable>
    </div>

    <!-- 自定义排序表头 -->
    <div>
      <el-dialog
        title="上传编辑好的导出模板"
        :visible.sync="show_upload"
        append-to-body
        width="40%"
        center
      >
        <div>
          <el-upload
            :action="uploadUrl"
            :show-file-list="true"
            :file-list="fileList"
            :limit="1"
            :http-request="uploadSectionFile"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">上传模板</div>
          </el-upload>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="show_upload = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>

    <!-- 新建 -->
    <el-dialog
      :title="subTitle"
      :visible.sync="show_cate"
      :before-close="closebut"
      :modal-append-to-body="false"
    >
      <div class="newForm">
        <el-form
          label-width="120px"
          ref="formCate"
          :rules="rules"
          status-icon
          :model="infosForm"
        >
          <div class="queryItem1">
            <el-form-item label="货区编号:" prop="number">
              <el-input
                :disabled="isDisabled"
                v-model="infosForm.number"
              ></el-input>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="货区名称:" prop="cargoareaName">
              <el-input v-model="infosForm.cargoareaName"></el-input>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="所属仓库:" prop="storageId">
              <el-select
                v-model="infosForm.storageId"
                filterable
                :disabled="isDisabled"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in storageAddr"
                  :key="item.id"
                  :label="item.storageName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="queryItem_member">
            <el-form-item label="货区会员号:">
              <div class="itemBox">
                <el-input
                  class="memberInput"
                  type="number"
                  placeholder="开始会员号"
                  v-model="infosForm.memberidStart"
                ></el-input>
                <div class="line"></div>
                <el-input
                  class="memberInput"
                  type="number"
                  placeholder="结束会员号"
                  v-model="infosForm.memberidEnd"
                ></el-input>
              </div>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="footerbox">
        <el-button @click="submitInfo()" class="seniorbut">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import draggable from "vuedraggable"; //拖拽组件
import paging from "@/components/pagings.vue";
import tool from "@/assets/js/tool";
import sorttable from "@/components/sortTemplate.vue";
export default {
  inject: ["reload"],
  components: {
    // mytables,
    draggable,
    sorttable,
    paging,
  },
  data() {
    return {
      queryData: {
        cargoareaName: "",
        storageName: "",
      },
      // ================
      pageNum: 1, //页码
      isActive: true, //输入框绑定动态class
      judge: false, //输入框绑定动态class
      MoreConditions: "更多条件", //输入框绑定动态class
      total: 0, //总条数
      sizeList: [10, 20, 50, 100, 200],
      size: 10, //一页几条
      nextPage: false, //是否有下一页
      show_sortTableHeard: false, //显示弹窗
      show_upload: false, //显示上传弹窗
      fullscreen: false,
      TableStyle: true,
      myTableHeard: [], //我的表头
      originalTabelHeadeTitle: [],
      selectLength: 0, //显示的长度
      allCheck: false, //全选
      tableHeigth: "50vh",
      tabelHeadeTitle: [
        {
          name: "货区编号",
          field: "cargoareaNumber",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
        },
        {
          name: "货区名称",
          field: "cargoareaName",
          width: "120",
          sort: 2,
          isShow: true,
          isTotal: false,
        },
        {
          name: "起始会员号",
          field: "memberidStart",
          width: "120",
          sort: 3,
          isShow: true,
          isTotal: false,
        },
        {
          name: "结束会员号",
          field: "memberidEnd",
          width: "120",
          sort: 3,
          isShow: true,
          isTotal: false,
        },
        {
          name: "所属仓库",
          field: "storageName",
          width: "120",
          sort: 3,
          isShow: true,
          isTotal: false,
        },
        {
          name: "创建时间",
          field: "cargoareaCreateTime",
          width: "160",
          sort: 4,
          isShow: true,
          isTotal: false,
        },
      ],
      tableData: [],
      totalArr: [], //需要合计的字段
      className: "", //导出需要
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileList: [],
      uploadUrl: "", //上传路径
      sortName: "模板名", //排序名
      pageGroupName: "cangkuhuoqu", //页面标识   (注意这里,每个列表定义一个唯一的标识,自定义,设置表头或者下载需要用到)
      show_cate: false,
      infosForm: {
        number: "",
        storageId: "",
        cargoareaName: "",
        memberidStart: "",
        memberidEnd: "",
        storageCargoareaId: "",
      },
      rules: {
        cargoareaName: [
          { required: true, message: "请输入货区名称", trigger: "blur" },
        ],
        number: [
          { required: true, message: "请输入货区编码", trigger: "blur" },
        ],
        storageId: [
          { required: true, message: "请选择仓库地址", trigger: "blur" },
        ],
      },
      currentItemArr: [],
      storageAddr: [], // 仓库地址
      subTitle: "新建货区",
      isDisabled: false,
    };
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle;
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1;
      }
      this.tabelHeadeTitle = arr;
    }
  },
  created() {
    this.uploadUrl = this.common.uploadUrl;
    if (this.originalTabelHeadeTitle.length == 0) {
      this.originalTabelHeadeTitle = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      );
    }
    this.myTableHeard = this.tabelHeadeTitle;
    this.getStorageAddr();
  },
  watch: {},
  // computed: {
  //   isDisabled() {
  //     return this.subTitle == "修改货区";
  //   },
  // },
  mounted() {
    const that = this;
    this.getData();
    this.fetTableHeight();
  },
  watch: {},
  methods: {
    //输入框绑定动态class
    more() {
      this.isActive = !this.isActive;
      this.judge = !this.judge;
      this.fetTableHeight();
      if (this.judge == false) {
        this.MoreConditions = "更多条件";
      } else {
        this.MoreConditions = "收起条件";
      }
    },
    getStorageAddr() {
      // 获取仓库地址
      Api.getStorageList({}).then((res) => {
        if (res.data.status == "success") {
          this.storageAddr = res.data.result || [];
        }
      });
    },
    addItem() {
      this.show_cate = true;
      this.subTitle = "新建货区";
      this.isDisabled = false;
      if (this.$refs.formCate) {
        this.$refs.formCate.resetFields();
      }
    },
    //关闭弹窗
    closebut() {
      this.infosForm = {
        number: "",
        storageId: "",
        cargoareaName: "",
        memberidStart: "",
        memberidEnd: "",
        storageCargoareaId: "",
      };
      this.show_cate = false;
      this.isDisabled = false;
    },
    //删除
    deletebut() {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let currentItemArr = this.currentItemArr;
        let array = {};
        let storageCargoareaIds = [];
        for (var i = 0; i < currentItemArr.length; i++) {
          storageCargoareaIds.push(parseInt(currentItemArr[i].cargoareaId));
        }
        let sign = tool.getSign({ storageCargoareaIds });
        array.storageCargoareaIds = storageCargoareaIds;
        array.sign = sign;
        Api.deleteStorageCargoarea(array).then((res) => {
          if (res.data.status == "success") {
            this.$message.success(res.data.message || "操作成功");
            this.getData();
          }
        });
      });
    },
    //修改
    updateItem() {
      if (this.currentItemArr.length == 1) {
        this.show_cate = true;
        this.subTitle = "修改货区";
        this.isDisabled = true;
        this.storageCargoareaDetail(this.currentItemArr[0].cargoareaId);
      } else {
        this.$message.warning("请选择一个需要修改的仓库货区");
      }
    },
    // 货区详情
    storageCargoareaDetail(id) {
      Api.storageCargoareaDetail({ storageCargoareaId: id }).then((res) => {
        let curItem = res.data.result || {};
        console.log(curItem);
        console.log("curItem");
        this.infosForm.number = curItem.number;
        this.infosForm.storageId = curItem.storageId;
        this.infosForm.cargoareaName = curItem.cargoareaName;
        this.infosForm.storageCargoareaId = curItem.storageCargoareaId;
        this.infosForm.memberidEnd = curItem.memberidEnd;
        this.infosForm.memberidStart = curItem.memberidStart;
      });
    },
    handleCommandTabs(m) {
      if (m == "a") {
        this.myModel();
      } else if (m == "b") {
        this.exportBtn();
      } else if (m == "c") {
        this.exportTemplateDownload();
      } else if (m == "d") {
        this.upLoadBtn();
      } else {
        this.screen();
      }
    },
    submitInfo() {
      // 新增和修改共用一个接口; 编号不得修改
      let self = this;
      this.$refs.formCate.validate((valid) => {
        if (valid) {
          let signParam = {};
          for (let item in self.infosForm) {
            signParam[item] = self.infosForm[item];
          }

          if (this.isDisabled) {
            let sign = tools.getSign(signParam);
            signParam.sign = sign;
            // 修改
            Api.updateStorageCargoarea(signParam).then((res) => {
              if (res.data.status == "success") {
                this.$message.success(res.data.message || "操作成功");
                self.show_cate = false;
                self.reload();
              }
            });
          } else {
            delete signParam.storageCargoareaId;
            let sign1 = tools.getSign(signParam);
            signParam.sign = sign1;
            Api.addStorageCargoarea(signParam).then((res) => {
              if (res.data.status == "success") {
                this.$message.success(res.data.message || "操作成功");
                self.show_cate = false;
                self.reload();
              }
            });
          }
        }
      });
    },
    /**
     * =========================按钮部分的方法=>开始??????????????????????????????????
     */

    //导出操作
    handleCommand_dc(command) {
      if (command == "dc") {
        // 导出
        this.exportBtn();
      } else if (command == "dcmb") {
        // 下载模板
        this.exportTemplateDownload();
      } else if (command == "scmb") {
        // 上传模板
        this.upLoadBtn();
      }
    },

    /**
     * =========================按钮部分的方法=>结束??????????????????????????????????
     */

    // ===============================================导出部分=开始======
    // 上传导出模板
    uploadSectionFile(param) {
      //console.log(param);
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            let fileName = res.data.result.fileName;

            let signArr = [
              {
                key: "groupName",
                val: this.pageGroupName,
              },
              {
                key: "templateName",
                val: this.sortName,
              },
              {
                key: "fileName",
                val: fileName,
              },
            ];
            let sign_ = tools.getMD5Sign(signArr);
            Api.saveExportTemplate({
              groupName: this.pageGroupName,
              templateName: this.sortName,
              fileName: fileName,
              sign: sign_,
            }).then((res) => {
              this.$message.success(res.data.message || "更新成功");
              this.show_upload = false;
            });
          }
        })
        .catch((err) => {});
    },

    // 下载用户导出模板
    exportTemplateDownload() {
      var fieldName = this.fieldName;
      var head = this.head;
      if (head.length == 0) {
        let fieldNames = [];
        let heads = [];
        for (var i = 0; i < this.myTableHeard.length; i++) {
          if (this.myTableHeard[i].isShow) {
            fieldNames.push(this.myTableHeard[i].field);
            heads.push(this.myTableHeard[i].name);
          }
        }
        fieldName = fieldNames;
        head = heads;
      }
      //加密数组格式
      let signArr = [
        {
          key: "fieldName",
          val: fieldName,
        },
        {
          key: "head",
          val: head,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      let param = {
        fieldName: fieldName,
        head: head,
        sign: sign,
      };
      Api.exportTemplateDownload(param).then((res) => {
        let downloadurl = res.data.result.fileUrl || "";
        //下载(这里其实是直接访问)
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 真正导出
    templateDataExport(customizedExportId) {
      let that = this;
      let datas = that.tableData;
      let parm = {
        customizedExportId: customizedExportId, //模板id
        className: that.className,
        data: datas,
      };
      let signarr = tools.getSignArr(parm);
      let signs_e = tools.getMD5Sign(signarr);
      Api.templateDataExport({
        customizedExportId: customizedExportId,
        className: that.className,
        data: JSON.stringify(datas),
        sign: signs_e,
      }).then((res) => {
        //下载(这里其实是直接访问)
        let downloadurl = res.data.result.fileUrl;
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 上传按钮
    upLoadBtn() {
      this.show_upload = true;
    },
    // 点击导出按钮
    exportBtn() {
      let that = this;
      let fieldName = [];
      let head = [];
      for (var i = 0; i < this.myTableHeard.length; i++) {
        if (this.myTableHeard[i].isShow) {
          fieldName.push(this.myTableHeard[i].field);
          head.push(this.myTableHeard[i].name);
        }
      }
      this.fieldName = fieldName;
      this.head = head;

      // 1,先获取用户导出模板
      Api.getUserExportTemplate({
        groupName: this.pageGroupName,
      }).then((res) => {
        if (res.data.result && res.data.result[0].customizedExportId) {
          // 有自定义模板

          let customizedExportId = res.data.result[0].customizedExportId;
          that.templateDataExport(customizedExportId);
        } else {
          //没有自定义模板,就是下载一个
          tools.dynamicExport(
            this.myTableHeard,
            this.className,
            this.tableData,
            "其他支出列表"
          );
        }
      });
    },
    // ===============================================导出部分=结束======

    /**
     * ******************************计算表格高度**开始*************************
     */
    // 重置table高度
    resetHeight() {
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        resolve();
      });
    },
    // 设置table高度
    fetTableHeight() {
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        // console.log(this.tableHeigth + "表格高度");
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },
    // 获取表格高度
    getHeight() {
      let windonHeight = document.body.clientHeight;
      let queryHeigth;
      let btnListHeight = this.$refs.btnList.offsetHeight + 16; // 按钮列表  16==上下的margin
      if (this.judge == true) {
        queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
      } else {
        queryHeigth = 50;
      }
      let pagsHeigth = 40; //分页组件
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 60 + 10; //60是顶部导航 ,10==预留空位
      return Math.floor(windonHeight - otherHeight) || "50vh"; //
    },
    /**
     * ******************************计算表格高度**结束*************************
     */

    // 刷新页面==>
    reload_s() {
      this.reload(); //App.Vue里面的方法
    },
    // 获取数据
    getData() {
      let formData = this.queryData;
      formData.pageStart = this.pageNum;
      formData.pageTotal = this.size;
      this.currentItemArr = [];
      Api.storageCargoareaList(formData).then((res) => {
        if (res.data.status == "success") {
          this.className = res.data.result.className || "";
          this.tableData = res.data.result.data || [];
          this.total = res.data.result.pageCount || 0;
        }
      });
    },
    // 查询按钮
    queryBtn_ok() {
      // 别的参数暂时没有
      this.pageNum = 1;
      this.getData();
    },
    // 重置按钮
    queryBtn_cz() {
      this.queryData = {
        cargoareaName: "",
        storageName: "",
      };
    },

    // 获取自定义的排序,没有就用默认的
    getGroupFindFieldSort() {
      let that = this;
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || [];
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard = JSON.parse(arr[0].sortObject);
            this.sortName = arr[0].sortName;
          }
        } else {
          that.myTableHeard = that.tabelHeadeTitle;
        }
        let totalArr = [];
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard.length; i++) {
          if (that.myTableHeard[i].isTotal) {
            totalArr.push(that.myTableHeard[i].field);
          }
        }
        that.totalArr = totalArr;
      });

      that.$nextTick(() => {
        that.$refs.mytable.doLayout();
      });
    },
    // 设置模板按钮
    myModel() {
      let that = this;
      that.show_sortTableHeard = true;
      that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard));
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    // 表格选择事件
    handleSelectionChange(e) {
      if (e.length > 0) {
        this.currentItemArr = e;
      } else {
        this.currentItemArr = [];
      }
    },
    //表格样式i
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      }
    },
    // 排序==>取消按钮
    cancelSortHeard() {
      this.show_sortTableHeard = false;
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
    },
    // 排序==>确认按钮
    confirmOk(e) {
      this.tabelHeadeTitle = e;
      this.myTableHeard = this.tabelHeadeTitle;
      let sortObject = this.myTableHeard;
      let signs = [
        {
          key: "sortName",
          val: this.sortName, //模板名称
        },
        {
          key: "groupName",
          val: this.pageGroupName, //页面标识
        },
        {
          key: "sortObject",
          val: JSON.stringify(sortObject),
        },
      ];

      let sign = tools.getMD5Sign(signs);

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      };
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getGroupFindFieldSort();
      });
      this.show_sortTableHeard = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      this.show_sortTableHeard = false;
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this;
      that.tabelHeadeTitle[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //全选
    checkAll(e) {
      let that = this;
      this.allCheck = e;
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
    },
    //关闭弹窗
    closeDias(e) {
      this.show_sortTableHeard = e;
    },
    //合计
    getSummaries(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property == that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return tools.mathToFixed(tools.add(prev, curr), 2);
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      return sums;
    },
    //分页
    handleSizeChange(val) {
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getData();
    },
  },
};
</script>

<style scoped lang="scss">
.tableBox {
  height: calc(100% - 260px);
}
.colorStyle {
  color: #f00;
}
.formbox ::v-deep {
  width: 80%;
  margin: 0 auto;
}
.headebut {
  width: 80%;
  margin: 0 auto;
  padding-left: 100px;
  box-sizing: border-box;
}
.newForm {
  margin-left: 80px;
  .queryItem1 {
    width: 60%;
    margin-top: 20px;
  }
  .queryItem_member {
    width: 60%;
    margin-top: 20px;
    .itemBox {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .memberInput {
        width: 120px;
        border: 1px solid #ccc;
      }
      .line {
        height: 2px;
        width: 40px;
        background-color: #999;
      }
    }
  }
}
.singleinp1 {
  min-width: 392px;
}
.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input,
.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input__inner {
  width: 100%;
}
.SingleChoicebox {
  width: 100%;
  padding: 0 20px;
  margin-top: 20px;
  box-sizing: border-box;
}
.el-input-number {
  width: 100%;
  height: 30px;
  line-height: 30px;
}
.footerbox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
</style>
